import React, { createContext, useEffect, useState } from 'react';
import { useLoadState } from 'hooks';

type CMSContextType = {
    top3: any[];
    cases: any[];
    categories: any[];
};

const DEFAULT: CMSContextType = { top3: [], cases: [], categories: [] };

export const CMSContext = createContext<CMSContextType>(DEFAULT);

interface CMSContextProviderProps {
    children: React.ReactNode;
}

export const CMSContextProvider = (props: CMSContextProviderProps) => {
    const [data, setData] = useState<CMSContextType>(DEFAULT);
    const { unsubscribe } = useLoadState('initCMS');

    useEffect(() => {
        const fetchCMSData = async () => {
            const casesFetched = await fetchFromCMS('posts', (c: any) => ({
                slug: slugify(c.acf.client + '-' + c.title.rendered),
                title: c.title.rendered,
                id: c.id,
                date: c.date,
                ...c.acf,
            }));
            const cases = casesFetched
                .filter((c: any) => (process.env.REACT_APP_PRODUCTION === '1' && !!c.showIn?.production) || (process.env.REACT_APP_PRODUCTION === '0' && !!c.showIn?.staging))
                .sort((a: any, b: any) => {
                    const aDate = new Date(a.date);
                    const bDate = new Date(b.date);
                    return bDate.getTime() - aDate.getTime();
                });
            const top3 = cases.slice(0, 3);
            const categories = await fetchFromCMS('categories', (category: any) => ({
                title: category.title.rendered,
                id: category.id,
                slug: category.slug,
                description: category.acf.description,
            }));
            setData({ top3, cases, categories });
            unsubscribe();
        };
        fetchCMSData();
    }, []);

    return <CMSContext.Provider value={data}>{props.children}</CMSContext.Provider>;
};

async function fetchFromCMS(slug: string, mapper?: (val: any) => any) {
    const fetched = await fetch(`https://files.diay21.com/wp-json/wp/v2/${slug}/`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await fetched.json();
    return mapper ? data.map(mapper) : data;
}

function slugify(name: string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return name
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}
