import styled from 'styled-components/macro';
import { ApartmentSVG, InstagramSVG, MailSVG, PhoneSVG } from './svg';
import { Color } from 'SharedStyles.styles';

type Props = {
    email?: string;
    tel?: string;
    ig?: string;
    address?: string;
};

export const Contact: React.FC<Props> = ({ email, tel, ig, address }) => {
    return (
        <Container>
            <Items>
                {email && (
                    <Item href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                        <Icon>
                            <MailSVG />
                        </Icon>
                        <Text>{email}</Text>
                    </Item>
                )}
                {tel && (
                    <Item href={`tel:${tel}`} target="_blank" rel="noopener noreferrer">
                        <Icon>
                            <PhoneSVG />
                        </Icon>
                        <Text>{tel}</Text>
                    </Item>
                )}
                {ig && (
                    <Item href={`https://www.instagram.com/${ig}/`} target="_blank" rel="noopener noreferrer">
                        <Icon>
                            <InstagramSVG height="90%" />
                        </Icon>
                        <Text>{`@${ig}`}</Text>
                    </Item>
                )}
                {address && (
                    <Item blockEvents>
                        <Icon>
                            <ApartmentSVG />
                        </Icon>
                        <Address>{address}</Address>
                    </Item>
                )}
            </Items>
        </Container>
    );
};

Contact.defaultProps = {
    email: 'contact@diay21.com',
    tel: '+49 1622 918 462',
    ig: 'dianachamani',
    address: 'DI AY 21 Media GmbH\nUrbanstr. 71\n10967 Berlin',
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
`;

const Items = styled.div`
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1vh;
    position: relative;
`;

const Item = styled.a<{ blockEvents?: boolean }>`
    cursor: pointer;
    color: ${Color.white};
    display: flex;
    justify-content: flex-start;
    gap: 2vw;
    align-self: stretch;
    padding: 1vh;
    box-sizing: border-box;
    border-radius: 10px;
    pointer-events: ${({ blockEvents }) => (blockEvents ? 'none' : '')};
    min-width: min-content;

    transition: background-color 0.3s;
    :hover {
        background-color: ${Color.grey3};
    }
`;

const Icon = styled.div`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 10vh;
    overflow: hidden;
`;

const Text = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const Address = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    white-space: pre;
`;
